import { Button, Typography, CircularProgress } from "@mui/material";
import React, { useEffect, useRef } from "react";
import Webcam from "react-webcam";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import muiStyles from '../../muiCustomStyles';
import './Camera.scss';

interface IdDocProps {
    idnumber: string;
    setView: (view: string) => void;
    setSelfie: (selfieimg: string) => void;
    instruction: string;
    facingMode: string | { [key: string]: any };
    payload: { [key: string]: any };
    selfie: string;
    setSucceeded: (succeededno: number) => void;
    succeeded: number;
    setResult: (res: { [key: string]: any }) => void;
    result: { [key: string]: any };
    requestid: string | null;
    callsexternal: any;
}


const CameraNoDetector: React.FC<IdDocProps> = ({ idnumber, setSelfie, setView, instruction, facingMode, payload, selfie, setSucceeded, succeeded, setResult, result, requestid,  callsexternal }: IdDocProps) => {
    const webcam = useRef<Webcam>(null);
    const [instructionstr, setInstruction] = React.useState<string>(instruction);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState('');

    muiStyles.buttonStyles.backgroundColor = payload.request.btn_color;
    muiStyles.buttonStyles['&:hover'].backgroundColor = payload.request.btn_color;
    muiStyles.buttonStyles.color = payload.request.btn_text_color;
    muiStyles.circleStyles.color = payload.request.btn_color;

    const [size, setWindowSize] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [photo, setPhoto] = React.useState<string>("");

    let videoConstraints = {
        facingMode: facingMode,
    };

    muiStyles.buttonStyles.maxWidth = 'inherit';

    useEffect(() => {
        if ("mediaDevices" in navigator) {
            // Access the API
            navigator.mediaDevices.getUserMedia({video: true})
            .then(function(stream) {
                // User has allowed access to the camera
                console.log('camera permission granted');
                setLoading(false);
            })
            .catch(function(err) {
                // User has denied access to the camera
                console.log('camera permission denied');
                setError('Camera permission denied, please enable camera permission in your browser settings.');
                setLoading(false);
            });
        } else {
            console.log("Error getting camera permission");
            setLoading(false);
            setError('Camera error.');
        }
    }, []);

    useEffect(() => {
        const doComparison = async () => {
            let imageSrc = photo;
            if (imageSrc !== "") {
                let i = imageSrc.indexOf(",");
                imageSrc = imageSrc.substring(i + 1);
                let sourceImage = encodeURIComponent(imageSrc);
                setSelfie(imageSrc);
                console.log("comparing for :" + idnumber);
                if (idnumber !== null) {
                    let compareresult = await callsexternal.selfieCompareFunction(idnumber, sourceImage, requestid,payload.apikey,payload.hash);
                    // console.log("Got compareresult:"+JSON.stringify(compareresult));
                    if (compareresult.status === "SUCCEEDED") {
                        if (compareresult.faces.length > 0) {
                            setResult({
                                selfie: {
                                    status: compareresult.status,
                                    statusMessage: compareresult.statusMessage,
                                    confidence: compareresult.faces[0].confidence
                                }
                            });
                            setTimeout(async () => {
                                if (payload.request.min_succeed === 1 || payload.request.min_succeed === 0) { //We only need 1 success and we have it!
                                    console.log('payload.request.min_succeed ' + payload.request.score_url);
                                    if (payload.request.score_url !== undefined && result !== undefined) {
                                        console.log("Sending to " + payload.request.score_url);
                                        await callsexternal.postToScoreUrl({
                                            selfie: {
                                                status: compareresult.status,
                                                statusMessage: compareresult.statusMessage,
                                                confidence: compareresult.faces[0].confidence
                                            },
                                            id_number: payload.identity_number,
                                            ref: payload.id
                                        }, payload.request.score_url, payload.hash);
                                    }
                                    window.location.replace(payload.request.return_url_success);
                                } else {
                                    setView("PREID");
                                }
                            }, 3000);
                        }
                        setSucceeded(1);
                    } else {
                        if (payload.request.min_succeed === 0) {
                            // console.log('payload.request.min_succeed ' + payload.request.score_url);
                                    if (payload.request.score_url !== undefined && result !== undefined) {
                                        console.log("Sending to " + payload.request.score_url);
                                        await callsexternal.postToScoreUrl({
                                            selfie: {
                                                status: compareresult.status,
                                                statusMessage: compareresult.statusMessage
                                            },
                                            id_number: payload.identity_number,
                                            ref: payload.id
                                        }, payload.request.score_url, payload.hash);
                                    }
                                    window.location.replace(payload.request.return_url_fail);
                        } else {
                            setResult({
                                selfie: {
                                    status: compareresult.status,
                                    statusMessage: compareresult.statusMessage
                                }
                            })
                            setView("PREID");
                        }
                    }
                } else {
                    setResult({ "error": "No ID Number in url parameters" })
                }
            }
        }
        doComparison();
    }, [photo]);

    const takePhoto = () => {
        if (webcam != null && webcam.current != null) {
            var currentWebcam = webcam.current;
            let imageSrc = currentWebcam.getScreenshot();
            if (imageSrc !== null) {
                setInstruction("Thank you for the photo");
                setPhoto(imageSrc);
            }
        }
    };

    if (photo === "") {
        return (
            <div className="SelfieApp-carema-view">
                {(!loading && error === '') && <div className="SelfieApp-carema-view__instruction">
                    {instructionstr}
                </div>}
                {(!loading && error === '') && <div className="SelfieApp-carema-view__wrapper">
                    <Webcam
                        audio={false}
                        ref={webcam}
                        videoConstraints={videoConstraints}
                        screenshotFormat='image/jpeg'
                        mirrored={false}
                        style={{
                            margin: "auto",
                            textAlign: "center",
                            top: 0,
                            left: 0,
                            right: 0
                        }}
                    />
                </div>}
                {(!loading && error === '') && <footer>
                    <Button
                    size="large"
                    variant="contained"
                    sx={muiStyles.buttonStyles}
                    onClick={() => takePhoto()}>
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{margin: '0 10px 0 0'}}>
                    <path d="M17.7704 5.625H20.5C20.8647 5.625 21.2144 5.76987 21.4723 6.02773C21.7301 6.28559 21.875 6.63533 21.875 7V19C21.875 19.3647 21.7301 19.7144 21.4723 19.9723C21.2144 20.2301 20.8647 20.375 20.5 20.375H4.5C4.13533 20.375 3.78559 20.2301 3.52773 19.9723C3.26987 19.7144 3.125 19.3647 3.125 19V7C3.125 6.63533 3.26987 6.28559 3.52773 6.02773C3.78559 5.76987 4.13533 5.625 4.5 5.625H7.22956C7.68557 5.625 8.1206 5.4334 8.42843 5.09697L9.66378 3.74685C9.73482 3.66922 9.83521 3.625 9.94044 3.625H15.0596C15.1648 3.625 15.2652 3.66922 15.3362 3.74685L16.5716 5.09697L17.0132 4.6929L16.5716 5.09697C16.8794 5.4334 17.3144 5.625 17.7704 5.625Z" stroke={payload.request.btn_text_color} strokeWidth="1.25" strokeLinejoin="round"/>
                    <path d="M16.375 12.5C16.375 14.6401 14.6401 16.375 12.5 16.375C10.3599 16.375 8.625 14.6401 8.625 12.5C8.625 10.3599 10.3599 8.625 12.5 8.625C14.6401 8.625 16.375 10.3599 16.375 12.5Z" stroke={payload.request.btn_text_color} strokeWidth="1.25"/>
                    </svg>
                    Submit</Button>
                </footer>}
                {loading && <CircularProgress sx={muiStyles.circleStyles} />}
                {error !== '' && <small style={{color:'red',padding: '20px'}}>{error}</small>}
                {(payload.request.min_succeed === 1 && payload.request.back_url && !loading) && <a className="backBtn" href={payload.request.back_url}>Back</a>}
            </div>
        );
    } else {
        return (
            <div className="SelfieApp-carema-view">
                <div className="SelfieApp-carema-view__instruction">
                    <div className="instruction">{instructionstr}</div>
                    {/* <div className="result">{JSON.stringify(result)}</div> */}
                </div>
                <div className="SelfieApp-carema-view__wrapper">
                    <img className="idimg" src={photo} alt="idimage"/>
                </div>
                <footer>
                    <CircularProgress sx={muiStyles.circleStyles} />
                </footer>
            </div>
        );
    }
}
export default CameraNoDetector;
