

import React, { useEffect, useRef } from "react";
import Webcam from "react-webcam";
import FaceMeshDetector from "../../FaceMeshDetector";
import './Camera.scss';

interface CameraProps {
    idnumber: string | null
    setView: (view: string) => void;
    setSelfie: (selfieimg: string) => void;
    setSucceeded: (succeededno: number) => void;
    payload: { [key: string]: any };
    setResult: (res: { [key: string]: any }) => void;
    result: { [key: string]: any };
    requestid: string | null;
    callsexternal: any;
}
const videoConstraints = {
    facingMode: "user",

};



const Camera: React.FC<CameraProps> = ({ idnumber, setView, setSelfie, payload, setSucceeded, setResult, result, requestid, callsexternal }: CameraProps) => {
    const [comparison, setComparison] = React.useState(false);
    const canvas = useRef<HTMLCanvasElement>(null);
    const webcam = useRef<Webcam>(null);
    const [instruction, setInstruction] = React.useState<string>("Center your face for optimal recognition");
    const [countDown, setCountDown] = React.useState(new Date().getTime());

    const [size, setWindowSize] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    let count = 0
    useEffect(() => {
        setTimeout(() => {
            if (webcam.current?.video?.readyState == 0) {
                setCountDown(countDown - new Date().getTime());
                console.log("readyState : " + webcam.current?.video?.readyState);
                console.log("Set countDown:" + countDown)
            } else {
                console.log("readyState : " + webcam.current?.video?.readyState);
                console.log("Running face detection!");
                runFaceDetect();
            }
        }, 2000);

    }, [countDown]);

    useEffect(() => {
        const doComparison = async () => {
            if (comparison === true) {
                setInstruction("Running comparison");
                if (webcam !== null && webcam.current != null) {
                    var currentWebcam = webcam.current;
                    let imageSrc = currentWebcam.getScreenshot();
                    if (imageSrc !== null) {
                        let i = imageSrc.indexOf(",");
                        imageSrc = imageSrc.substring(i + 1);
                        let sourceImage = encodeURIComponent(imageSrc);
                        setSelfie(imageSrc);
                        console.log("comparing for :" + idnumber);
                        if (idnumber !== null) {
                            let compareresult = await callsexternal.selfieCompareFunction(idnumber, sourceImage, requestid, payload.apikey, payload.hash);
                            // console.log("Got compareresult:" + JSON.stringify(compareresult));
                            if (compareresult.status === "SUCCEEDED") {
                                if (compareresult.faces.length > 0) {
                                    setResult({
                                        selfie: {
                                            status: compareresult.status,
                                            statusMessage: compareresult.statusMessage,
                                            confidence: compareresult.faces[0].confidence
                                        }
                                    });
                                    setTimeout(async () => {
                                        if (payload.request.min_succeed === 1 || payload.request.min_succeed === 0) { //We only need 1 success and we have it!
                                            // console.log('payload.request.min_succeed ' + payload.request.score_url);
                                            if (payload.request.score_url !== undefined && result !== undefined) {
                                                console.log("Sending to " + payload.request.score_url);
                                                await callsexternal.postToScoreUrl({
                                                    selfie: {
                                                        status: compareresult.status,
                                                        statusMessage: compareresult.statusMessage,
                                                        confidence: compareresult.faces[0].confidence
                                                    },
                                                    id_number: payload.identity_number,
                                                    ref: payload.id
                                                }, payload.request.score_url, payload.hash);
                                            }
                                            window.location.replace(payload.request.return_url_success);
                                        } else {
                                            setView("PREID");
                                        }
                                    }, 3000);
                                }
                                setSucceeded(1);
                            } else {
                                if (payload.request.min_succeed === 0) {
                                    console.log('payload.request.min_succeed ' + payload.request.score_url);
                                            if (payload.request.score_url !== undefined && result !== undefined) {
                                                console.log("Sending to " + payload.request.score_url);
                                                await callsexternal.postToScoreUrl({
                                                    selfie: {
                                                        status: compareresult.status,
                                                        statusMessage: compareresult.statusMessage,
                                                    },
                                                    id_number: payload.identity_number,
                                                    ref: payload.id
                                                }, payload.request.score_url, payload.hash);
                                            }
                                            window.location.replace(payload.request.return_url_fail);
                                } else {
                                    setResult({
                                        selfie: {
                                            status: compareresult.status,
                                            statusMessage: compareresult.statusMessage
                                        }
                                    })
                                    setView("PREID");
                                }
                            }
                        } else {
                            setResult({ "error": "No ID Number in url parameters" })
                        }
                    }
                }
            }
        }

        doComparison();
    }, [comparison]);
    let detector: FaceMeshDetector;
    const runFaceDetect = async () => {
        detector = new FaceMeshDetector();
        await detector.loadDetector();
        await detect();
    };
    const draw = (predictions: any, color: string) => {
        try {
            if (canvas.current) {
                const ctx = canvas.current.getContext("2d");
                if (ctx) {
                    predictions.forEach((prediction: any) => {
                        drawBox(ctx, prediction, color);
                        drawFaceMesh(ctx, prediction);
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const drawText = (text: string, y: number) => {
        try {
            if (canvas.current) {
                const ctx = canvas.current.getContext("2d");
                if (ctx) {
                    ctx.font = "18px Arial";
                    ctx.fillStyle = "#ffffff"
                    ctx.fillText(text, 50, y);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    const drawBox = (ctx: any, prediction: any, color: string) => {
        const x = prediction.box.xMin;
        const y = prediction.box.yMin;
        const width = prediction.box.width;
        const height = prediction.box.height;
        ctx.lineWidth = 1;
        ctx.beginPath();
        //ctx.rect(x, y, width, height);
        ctx.ellipse(x + width / 2, y + height / 2 - 20, width / 2 + 20, height / 2 + 30, 0, 0, 2 * Math.PI);
        ctx.strokeStyle = color;
        ctx.setLineDash([3, 3]);
        ctx.stroke();
    };
    const drawFaceMesh = (ctx: any, prediction: any) => {
        prediction.keypoints.forEach((item: any, index: string) => {
            const x = item.x;
            const y = item.y;
            ctx.fillRect(x, y, 2, 2);
            ctx.fillStyle = "#69ffe1";
        });
    };
    function canvas_arrow(fromx: number, fromy: number, tox: number, toy: number, angle1: number, color: string) {
        //variables to be used when creating the arrow
        if (canvas.current) {
            const ctx = canvas.current.getContext("2d");
            const width = 1;
            var headlen = 15;
            // This makes it so the end of the arrow head is located at tox, toy, don't ask where 1.15 comes from
            tox -= Math.cos(angle1) * ((width * 1.15));
            toy -= Math.sin(angle1) * ((width * 1.15));

            var angle = Math.atan2(toy - fromy, tox - fromx);
            if (ctx) {
                //starting path of the arrow from the start square to the end square and drawing the stroke
                ctx.beginPath();
                ctx.moveTo(fromx, fromy);
                ctx.lineTo(tox, toy);
                ctx.strokeStyle = color;
                ctx.lineWidth = width;
                ctx.stroke();

                //starting a new path from the head of the arrow to one of the sides of the point
                ctx.beginPath();
                ctx.moveTo(tox, toy);
                ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 7), toy - headlen * Math.sin(angle - Math.PI / 7));
                ctx.strokeStyle = color;
                ctx.lineWidth = width;
                ctx.stroke();

                //path from the side point of the arrow, to the other side point
                ctx.moveTo(tox - headlen * Math.cos(angle + Math.PI / 7), toy - headlen * Math.sin(angle + Math.PI / 7));

                //path from the side point back to the tip of the arrow, and then again to the opposite side point
                ctx.lineTo(tox, toy);
                ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 7), toy - headlen * Math.sin(angle - Math.PI / 7));

                //draws the paths created above
                ctx.strokeStyle = color;
                ctx.lineWidth = width;
                ctx.stroke();
            }
        }
    }
    const detect = async () => {
        let color = "#DD312A";//red
        if (comparison === true) {
            color = "#1976D2";//blue
        }
        if (webcam.current) {
            const webcamCurrent = webcam.current;
            if (webcamCurrent != null && webcamCurrent.video != null) {
                if (webcamCurrent.video.readyState === 4) {
                    const videoWidth = webcamCurrent.video.videoWidth;
                    const videoHeight = webcamCurrent.video.videoHeight;
                    if (canvas != null && canvas.current != null) {
                        canvas.current.width = videoWidth;
                        canvas.current.height = videoHeight;
                    }
                    setWindowSize({
                        width: videoWidth,
                        height: videoHeight
                    });
                    const predictions = await detector.detector?.estimateFaces(webcamCurrent.video);
                    if (predictions != undefined && predictions.length) {

                        if (predictions.length > 1) {
                            setInstruction("Only one face allowed in photo")
                        } else {
                            let x = predictions[0].box.xMin + predictions[0].box.width / 2;
                            let y = predictions[0].box.yMin + predictions[0].box.height / 2;;
                            let centerx = videoWidth / 2;
                            let centery = videoHeight / 2;
                            let message = "";

                            if ((x - centerx) > 30) {
                                message = message + "Move face to the left";
                                canvas_arrow(predictions[0].box.xMin - 20, y, predictions[0].box.xMin - 40, y, 270, color)
                            }
                            if ((x - centerx) < -30) {
                                message = message + "Move face to the right";
                                canvas_arrow(predictions[0].box.xMax + 20, y, predictions[0].box.xMax + 40, y, 90, color)
                            }
                            if ((y - centery) < -30) {
                                message = message + " Move face down";
                                canvas_arrow(x, predictions[0].box.yMax + 60, x, predictions[0].box.yMax + 80, 180, color)
                            }
                            if ((y - centery) > 30) {
                                message = message + " Move face up";
                                canvas_arrow(x, predictions[0].box.yMin - 60, x, predictions[0].box.yMin - 80, 360, color)
                            }

                            // if(message){
                            //     setInstruction(message)
                            // }

                            if (Math.abs(x - centerx) < 30 && Math.abs(y - centery) < 30) {
                                setComparison(true);
                                requestAnimationFrame(() => {
                                    draw(predictions, "#1976D2");//blue
                                });

                            } else {
                                requestAnimationFrame(() => {
                                    draw(predictions, color);
                                });
                            }
                        }
                        setTimeout(async () => {
                            count++;
                            await detect();
                        }, 250);

                    } else {
                        setTimeout(async () => {
                            count++;
                            await detect();
                        }, 250);
                    }
                }
            }
        };
    };
    // if (instruction != "") {
    //     drawText(instruction, 30)
    // }
    // if (result.selfie != undefined&&result.selfie.statusMessage != undefined) {
    //     drawText(JSON.stringify(result.selfie.statusMessage), 50)
    // }

    useEffect(() => {
        if (result.selfie != undefined && result.selfie.statusMessage != undefined) {
            setInstruction(JSON.stringify(result.selfie.statusMessage))
        }
    }, [result]);

    return (
        <div className="SelfieApp-carema-view">
            <div className="SelfieApp-carema-view__instruction">
                {instruction}
            </div>
            <div className="SelfieApp-carema-view__wrapper">
                <Webcam
                    audio={false}
                    ref={webcam}
                    videoConstraints={videoConstraints}
                    screenshotFormat='image/jpeg'
                    mirrored={false}
                    width={size.width}
                    height={size.height}
                    style={{
                        position: "absolute",
                        margin: "auto",
                        textAlign: "center",
                        top: 0,
                        left: 0,
                        right: 0
                    }}
                />
                <canvas
                    ref={canvas}
                    style={{
                        position: "absolute",
                        margin: "auto",
                        textAlign: "center",
                        top: 0,
                        left: 0,
                        right: 0
                    }}
                />
            </div>
            {(payload.request.min_succeed === 1 && payload.request.back_url) && <a className="backBtn" href={payload.request.back_url}>Back</a>}
        </div>
    );
}

export default Camera;
