

export class callsExternal {
    async postToScoreUrl(score: { [key: string]: any }, url: string, hash: string): Promise<any> {
        try {
            //console.log('postToScoreUrl',{score,url,hash});
            const res = fetch(url, {
                method: 'post',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Api-Key': hash
                },
                body: JSON.stringify(score)
            });
            var response = (await res);

            console.log("got back :" + response.status);
        } catch (err) {
            console.error(err);
        }
    }

    async selfieCompareFunction(idnumber: string, selfie: string, requestid: string | null,apikey:string, hash:string): Promise<any> {
        try {
            let request = JSON.stringify({
                "idnumber": idnumber,
                "selfie": selfie,
                "refresh": false,
                "threshold": 90,
                "requestid": requestid,
                "apikey":apikey
            });
            const res = fetch(process.env.REACT_APP_SELFIE_ROOT_URL + "/v1/selfiecompare", {
                method: 'post',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Api-Key': hash
                },
                body: request
            });
            var response = (await res);
            var result = await (response).json();
            result.code = response.status;

            return result;
        } catch (err) {
            console.log("got post error:" + err);
            return err;
        }
    }


    async documentCompareFunction(idnumber: string, selfie: string, requestid: string | null, holdingid: string | null, iddoc: string | null,apikey:string, hash:string): Promise<any> {
        try {
            let request;
            if (holdingid != null) {
                request = JSON.stringify({
                    "idnumber": idnumber,
                    "selfie": selfie,
                    "holdingid": holdingid,
                    "requestid": requestid,
                    "apikey":apikey
                });
            }
            if (iddoc != null) {
                request = JSON.stringify({
                    "idnumber": idnumber,
                    "selfie": selfie,
                    "iddoc": iddoc,
                    "requestid": requestid,
                    "apikey":apikey
                });
            }
            const res = fetch(process.env.REACT_APP_SELFIE_ROOT_URL + "/v1/idverify", {
                method: 'post',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Api-Key': hash
                },
                body: request
            });
            var response = (await res);
            var result = await (response).json();
            result.code = response.status;
            return result;
        } catch (err) {
            console.log("got post error:" + err);
            return err;
        }
    }

    async postToEventLog(eventpayload: any): Promise<any> {
        // try {
            
        //     // const res = fetch(url, {
        //     //     method: 'post',
        //     //     headers: {
        //     //         'accept': 'application/json',
        //     //         'Content-Type': 'application/json',
        //     //         'X-Api-Key': hash
        //     //     },
        //     //     body: JSON.stringify(score)
        //     // });
        //     // var response = (await res);

        //     console.log(eventpayload);
        // } catch (err) {
        //     console.error(err);
        // }
    }
}
